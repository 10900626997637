import React from 'react'
import Styled from 'styled-components'
import {Row, Col} from 'react-bootstrap'

import Description from '../Description'
import RzetelnaFirma from '../RzetelnaFirma/RzetelnaFirma'

const Prefooter = () =>{
    return(  
        <StyledRow className="bg-white text-dark p-5 m-0 w-100 ">
            <Col xs={12} xl={7} className="mb-5">
                <Description/>
            </Col>
            <Col xs={12} xl={5}>
                <RzetelnaFirma/>
            </Col>
        </StyledRow>
    )
}

export default Prefooter
const StyledRow = Styled(Row)`
  @media only screen and (max-width:992px){
    padding:3rem 0 3rem 0 !important;
  }
`
