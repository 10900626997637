import React from 'react'
import styled from 'styled-components'
import {Container, Row} from 'react-bootstrap'

import NapiszDoNas from './Buttons/napiszDoNas'

const Description = () => (
  <Container className="mr-5"> 
    <Row >
      <Header>JASTRYCH</Header>
    </Row>
    <Row>
      <Desc>
        Jastrych S.C. działa na pomorskim rynku już od 2004 roku. Jesteśmy firmą rodzinną,
        która bazuje na wieloletnim doświadczeniu, popartym przez wcześniejszą pracę w firmach
        budowlanych o podobnej specjalizacji. Wykonujemy wylewki i posadzki od lat. Jesteśmy
        spółką o szerokim zasięgu działania, obsługujemy klientów z całego kraju.
        Naszym głównym profilem działania jest wykonywanie posadzek przemysłowych,
        czesanek czy betonów polerowanych. Specjalizujemy się w naprawie różnego rodzaju
        powierzchni. Nasza firma zapewnia wysoki, profesjonalny standard obsługi klienta
        od momentu przyjęcia zlecenia aż do zakończenia pracy. Podążając za innowacyjnymi
        rozwiązaniami dla budownictwa zapewniamy wykonanie wylewek nie tylko tradycyjnych
        betonowych, ale też nowoczesnych i bardziej elastycznych.
      </Desc>
    </Row>
    <Row>
      <NapiszDoNas/>
    </Row>
  </Container>
  )

export default Description

const Header = styled.h2`
  width:100%;
  text-align: center;
  font-size: 2.2rem;
  font-weight:700;
  color: white;
  background-color:var(--blue);
`;

const Desc = styled.p`
  text-align: justify;
  text-justify: inter-word;
  font-size: 1.3rem;
  font-weight: 500;
  padding-top: 3%;
  padding-bottom: 5%;
`;