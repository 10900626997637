import React from 'react'

import Styled from 'styled-components'
import {Container, Row, Col} from 'react-bootstrap'

import {StyledContainer, ContentWrapper, ImageTextContainer, BannerImg} from './layoutStyles'

import Seo from '../components/seo'
import MainMenu from '../components/MainMenu'
import Header from '../components/header'
import Prefooter from '../components/Prefooter/Prefooter'
import Footer from '../components/footer'

export default ({headerPhoto, children}) => {
  return (
    <StyledContainer>
      <Container fluid className="p-0">
        <Seo/>
        <StyledRowHeader noGutters className="w-100">
          <Header />
        </StyledRowHeader>
        <Row noGutters>
          <Row noGutters>
            <ImageTextContainer>
              <BannerImg>
                {headerPhoto}
              </BannerImg>
              <MainMenu/>
            </ImageTextContainer>
          </Row>
          <Row className="d-flex justify-content-center mt-5 mx-0 w-100">
            <Col lg={10}>
              <ContentWrapper>
                {children}
              </ContentWrapper>
            </Col>
          </Row>
          <Prefooter/>
          <Footer />
        </Row>
      </Container>
    </StyledContainer>
  )
}

const StyledRowHeader = Styled(Row)`
  @media only screen and (max-width: 992px){
    position: sticky;
    top:0;
    z-index: 1020
  }
`