import React from 'react'

import Layout from '../layouts/layout'

import Description from "../components/Description"
import Galeria from '../components/Galeria'

import banerjpg from '../images/headerPhotos/galeria.jpg'

const contact = ({data}) => {
  return(
    <Layout
      headerPhoto={<img src={banerjpg} alt="kontakt"/>}
      prefooterElement={<Description/>}
    >
      <h2>GALERIA</h2>
      <Galeria/>      
    </Layout>
  )
}

export default contact