import React, { useState, useCallback } from 'react'
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import Styled from 'styled-components'

import { photos } from "./photos";


function Galeria() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };

    return (
      <div>
        <StyledImages>
          <Gallery photos={photos} onClick={openLightbox} />    
        </StyledImages>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <StyledFullImages>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
              </StyledFullImages>
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  }

export default Galeria

const StyledImages = Styled.div`
  img{
    object-fit: cover;
  }
`;

const StyledFullImages = Styled.div`
  img{
    max-height:100vh;
  }
`;