import zdj1 from '../images/photos/1.jpg'
import zdj2 from '../images/photos/2.jpg'
import zdj3 from '../images/photos/3.jpg'
import zdj4 from '../images/photos/4.jpg'
import zdj5 from '../images/photos/5.jpg'
import zdj6 from '../images/photos/6.jpg'
import zdj7 from '../images/photos/7.jpg'
import zdj8 from '../images/photos/8.jpg'
import zdj9 from '../images/photos/9.jpg'
import zdj10 from '../images/photos/10.jpg'
import zdj11 from '../images/photos/11.jpg'
import zdj12 from '../images/photos/12.jpg'
import zdj13 from '../images/photos/13.jpg'
import zdj14 from '../images/photos/14.jpg'
import zdj15 from '../images/photos/15.jpg'
import zdj16 from '../images/photos/16.jpg'
import zdj17 from '../images/photos/17.jpg'
import zdj18 from '../images/photos/18.jpg'
import zdj19 from '../images/photos/19.jpg'
import zdj20 from '../images/photos/20.jpg'
import zdj21 from '../images/photos/21.jpg'
import zdj22 from '../images/photos/22.jpg'
import zdj23 from '../images/photos/23.jpg'
import zdj24 from '../images/photos/24.jpg'
import zdj25 from '../images/photos/25.jpg'
import zdj26 from '../images/photos/26.jpg'
import zdj27 from '../images/photos/27.jpg'



export const photos = [
    {
      src: zdj1,
      width: 8,
      height: 4
    },
    {
      src: zdj2,
      width: 4,
      height: 3
    },
    {
      src: zdj3,
      width: 3,
      height: 4
    },
    {
      src: zdj4,
      width: 3,
      height: 4
    },
    {
      src: zdj5,
      width: 3,
      height: 4
    },
    {
      src: zdj6,
      width: 4,
      height: 3
    },
    {
      src: zdj7,
      width: 8,
      height: 4
    },
    {
      src: zdj8,
      width: 4,
      height: 3
    },
    {
      src: zdj9,
      width: 4,
      height: 3
    },
    {
      src: zdj10,
      width: 3,
      height: 4
    },
    {
      src: zdj11,
      width: 4,
      height: 3
    },
    {
      src: zdj12,
      width: 4,
      height: 3
    },
    {
      src: zdj13,
      width: 4,
      height: 3
    },
    {
      src: zdj14,
      width: 4,
      height: 3
    },
    {
      src: zdj15,
      width: 3,
      height: 4
    },
    {
      src: zdj16,
      width: 4,
      height: 3
    },
    {
      src: zdj17,
      width: 3,
      height: 4
    },
    {
      src: zdj18,
      width: 4,
      height: 3
    },
    {
      src: zdj19,
      width: 3,
      height: 4
    },
    {
      src: zdj20,
      width: 3,
      height: 4
    },
    {
      src: zdj21,
      width: 4,
      height: 3
    },
    {
      src: zdj22,
      width: 3,
      height: 4
    },
    {
      src: zdj23,
      width: 3,
      height: 4
    },
    {
      src: zdj24,
      width: 4,
      height: 3
    },
    {
      src: zdj25,
      width: 3,
      height: 4
    },
    {
      src: zdj26,
      width: 4,
      height: 3
    },
    {
      src: zdj27,
      width: 3,
      height: 4
    }
  ];
